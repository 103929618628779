<template>
  <div class="">
    <select-entity
      v-model="list"
      btnText="添加核销人"
      type="verifierBy"
      :customQuery="customQuery"
      multiple
      hide-list
      @change="dispatchEvent"
    ></select-entity>
    <a-table ref="dataTable" :columns="columns" :row-key="(record) => record.id" :data-source="list">
      <template v-slot:action="text, record, index">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn danger" @click.stop="delRow(record, index)">删除</a>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { clone, isArray } from "../../../../common/js/tool";
import { getSystemRoleName } from "../../../../permission/subsystem";

export default {
  name: "VerifierManage",
  model: {
    event: "change",
  },
  props: {
    value: Array,
    show: Boolean, // 仅展示
    privilege: [String, Number],
  },
  data() {
    return {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
        showSizeChanger: true,
      },
    };
  },
  computed: {
    customQuery() {
      if (this.privilege) {
        return `&filter[system]=2&filter[privilege]=${this.privilege}`;
      }
      return "";
    },
    columns() {
      let columns = [
        {
          title: "核销人名称",
          dataIndex: "user_id",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <open-data type="userName" openid={text[0]} />;
            } else {
              return "-";
            }
          },
        },
        // { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          customRender: (text) => {
            return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
          },
        },
      ];
      if (!this.show) {
        columns.push({ title: "操作", key: "action", fixed: "right", scopedSlots: { customRender: "action" } });
      }
      return columns;
    },
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.list = clone(this.value);
    },
    dispatchEvent() {
      let list = this.list;
      if (list) {
        this.$emit("change", isArray(list) ? list : [list]);
      } else {
        this.$emit("change", null);
      }
    },
    delRow(record, index) {
      this.list.splice(index, 1);
      this.dispatchEvent();
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <div class="common-title">活动基本信息</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="活动名称" prop="name">
            <a-input v-model="form.name" placeholder="输入活动名称（最多30字）" :maxLength="30" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="活动时间" prop="activity_time">
            <a-range-picker
              v-model="form.activity_time"
              :show-time="{ format: timeFormat }"
              :format="format"
              :value-format="format"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="timeChange($event, 'activity_time')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="活动地点" prop="location">
            <a-input
              v-model="form.location"
              placeholder="输入活动地点（最多30字）"
              :maxLength="30"
              allowClear
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可打卡时间" prop="clock_in_time">
            <a-range-picker
              v-model="form.clock_in_time"
              :show-time="{ format: timeFormat }"
              :format="format"
              :value-format="format"
              :placeholder="['开始时间', '结束时间']"
              allowClear
              @change="timeChange($event, 'clock_in_time')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="报名时间" prop="enroll_time">
            <a-range-picker
              v-model="form.enroll_time"
              :show-time="{ format: timeFormat }"
              :format="format"
              :value-format="format"
              :placeholder="['开始时间', '截止时间']"
              allowClear
              @change="timeChange($event, 'enroll_time')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="名额限制" required validateStatus="success">
            <a-form-model-item label="" prop="is_limit" class="input-item">
              <a-radio-group v-model="form.is_limit">
                <a-radio :value="item.value" v-for="item in numberOptions" :key="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="" prop="quota_num" class="input-item" v-if="form.is_limit == 2">
              <a-input-number
                v-model="form.quota_num"
                placeholder="请输入数字"
                :min="1"
                :step="1"
                allowClear
              ></a-input-number>
            </a-form-model-item>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="活动类型" prop="activity_type_id">
            <a-select placeholder="选择活动类型" :options="typeOptions" v-model="form.activity_type_id" allowClear>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="招募总人数" prop="recruit_num">
            <a-input-number
              v-model="form.recruit_num"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              style="width: 100%;"
            ></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="需要报备" prop="is_report">
            <a-checkbox-group v-model="form.is_report" name="checkboxgroup" :options="reportOptions" />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="活动封面" prop="img">
            <form-upload v-model="form.img" uploadTip="请上传jpg、png、jpeg格式的图片，最多一张"></form-upload>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="活动介绍" prop="info" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <rich-editor v-model="form.info" placeholder="输入活动介绍"></rich-editor>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">核销人管理</div>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="" :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <VerifierManage v-model="form.userRole" :privilege="4"></VerifierManage>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <div class="common-title">报名限制条件</div>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可确认志工报名名单的人（多选）" prop="confirm_people">
            <a-select
              placeholder="请选择"
              mode="multiple"
              :options="catOptions"
              v-model="form.confirm_people"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可进行报名的志工状态（多选）">
            <a-select
              placeholder="请选择"
              mode="multiple"
              :options="volunStatusOptions"
              v-model="form.volunteer_status"
              allowClear
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <upload-volunteer v-model="form.volunteer_id" label="仅以下志工可报名"></upload-volunteer>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="可进行报名的年龄范围">
            <a-space>
              <a-input-number
                v-model="form.min_age"
                placeholder="请输入最小年龄（岁）"
                :min="1"
                :step="1"
                allowClear
              ></a-input-number>
              <span>~</span>
              <a-input-number
                v-model="form.max_age"
                placeholder="请输入最大年龄（岁）"
                :min="1"
                :step="1"
                allowClear
              ></a-input-number>
            </a-space>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item :label-col="halfLabelCol" :wrapper-col="halfWrapperCol">
            <template v-slot:label>
              <a-space size="large">
                <span>仅已选中的小组可参与</span>
                <a class="txt-btn" @click="addGroup">添加小组</a>
              </a-space>
            </template>
            <div v-for="(item, index) in form.joinGroup" :key="item.key">
              <a-form-model-item label="" class="select-item mb-0">
                <a-select
                  placeholder="请选择小组（支持搜索）"
                  :options="groupOptions"
                  v-model="item.volunteer_group_id"
                  allowClear
                  show-search
                  option-filter-prop="children"
                >
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="最多报名人数："
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                class="select-item limit-item mb-0"
              >
                <a-input-number
                  v-model="item.enrollment"
                  placeholder="请输入数字"
                  :min="1"
                  :step="1"
                  allowClear
                ></a-input-number>
              </a-form-model-item>
              <span class="txt-btn danger del-btn" @click="delGroup(index)">删除</span>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import {
  clone,
  dataConvertOptions,
  isEmpty,
  joinListKey,
  randomString,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../../../common/js/tool";
import { numberOptions, catOptions, volunStatusOptions, reportOptions } from "../../../../common/hr/volunteer";
import VerifierManage from "./VerifierManage.vue";

const multipleKeys = ["is_report", "confirm_people", "volunteer_status"];
const timeConf = {
  activity_time: ["activity_start_time", "activity_end_time"],
  clock_in_time: ["start_time", "end_time"],
  enroll_time: ["enroll_start", "enroll_end"],
};

export default {
  name: "ActivityForm",
  components: {
    VerifierManage,
  },
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      format: "YYYY-MM-DD HH:mm",
      timeFormat: "HH:mm",
      form: {},
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        activity_time: [{ required: true, message: "请选择活动时间", trigger: "change" }],
        location: [{ required: true, message: "请输入活动地点", trigger: "blur" }],
        clock_in_time: [{ required: true, message: "请选择可打卡时间", trigger: "change" }],
        enroll_time: [{ required: true, message: "请选择报名时间", trigger: "change" }],
        is_limit: [{ required: true, message: "请选择是否限制名额", trigger: "change" }],
        quota_num: [{ required: true, message: "请输入限制名额", trigger: "blur" }],
        activity_type_id: [{ required: true, message: "请选择活动类型", trigger: "change" }],
        recruit_num: [{ required: true, message: "请输入招募总人数", trigger: "blur" }],
        is_report: [{ required: true, message: "请选择报备选项", trigger: "change" }],
        img: [{ required: true, message: "请上传活动封面", trigger: "change" }],
        info: [{ required: true, message: "请输入活动介绍", trigger: "blur" }],
        confirm_people: [{ required: true, message: "请选择可确认志工报名名单的人", trigger: "change" }],
      },
      typeOptions: [],
      reportOptions,
      numberOptions,
      catOptions,
      volunStatusOptions,
      groupOptions: [],
    };
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.getType();
    this.getGroup();
    // this.init();
  },
  methods: {
    getType() {
      this.$axios({
        url: "/admin/volunteer-activity-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        let keys = Object.keys(timeConf);
        keys.forEach((key) => {
          let conf = timeConf[key];
          let start_key = conf[0];
          let end_key = conf[1];
          if (form[start_key] && form[end_key]) {
            form[key] = [timestampConvertString(form[start_key]), timestampConvertString(form[end_key])];
          }
        });
        multipleKeys.forEach((key) => {
          if (!isEmpty(form[key])) {
            form[key] = form[key].split(",").map(Number);
          } else {
            form[key] = [];
          }
        });
        this.form = form;
      }
    },
    // 获取小组列表
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    timeChange(dateString, prop) {
      let conf = timeConf[prop];
      let start_key = conf[0];
      let end_key = conf[1];
      if (dateString.length > 0) {
        this.$set(this.form, start_key, stringConvertTimestamp(dateString[0]));
        this.$set(this.form, end_key, stringConvertTimestamp(dateString[1]));
      } else {
        this.$set(this.form, start_key, undefined);
        this.$set(this.form, end_key, undefined);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            multipleKeys.forEach((key) => {
              if (!isEmpty(form[key])) {
                form[key] = form[key].join(",");
              }
            });
            // 核销人
            if (form.userRole) {
              form.user_role_id = joinListKey(form.userRole);
            }
            // 仅已选中的小组可参与
            if (form.joinGroup) {
              let oldGroup = clone(this.initial?.joinGroup || []);
              let join_group = {
                add: [],
                update: [],
                delete: [],
              };
              form.joinGroup = form.joinGroup?.filter(
                (item) => !isEmpty(item.id) || (!isEmpty(item.volunteer_group_id) && !isEmpty(item.enrollment))
              ); // 筛选 存在id 和 名称与报名人数填写完整的
              form.joinGroup?.forEach((item) => {
                if (item.id) {
                  join_group.update.push(item);
                  // 去除当前仍存在的，剩下的为删除的
                  let index = oldGroup.findIndex((old) => old.id == item.id);
                  oldGroup.splice(index, 1);
                } else if (item.key) {
                  join_group.add.push(item);
                }
              });
              join_group.delete = oldGroup;
              form.join_group = JSON.stringify(join_group);
            }
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
    confirm(formData) {
      this.form = formData;
      this.visible = false;
    },
    // 添加小组
    addGroup() {
      let item = {
        key: randomString(16),
      };
      let joinGroup = this.form.joinGroup;
      if (joinGroup) {
        joinGroup.push(item);
      } else {
        joinGroup = [item];
      }
      this.$set(this.form, "joinGroup", joinGroup);
    },
    // 删除小组
    delGroup(index) {
      this.form.joinGroup.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 32px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.select-item {
  display: inline-block;
  ::v-deep &.limit-item {
    margin-left: 32px;
    .ant-form-item-label {
      line-height: 32px;
    }
  }
  .ant-select {
    width: 240px;
  }
}
.del-btn {
  margin-left: 64px;
  line-height: 32px;
}
</style>

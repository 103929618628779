<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <ActivityForm ref="editForm" :initial="initial" v-show="edit" class="area-content"></ActivityForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions title="课程基本信息" :list="basicList" :detail="detail" class="mb-24"></Descriptions>
        <div>
          <div class="ant-descriptions-title">核销人管理</div>
          <a-table ref="dataTable" :columns="columns" :row-key="(record) => record.id" :data-source="list"> </a-table>
        </div>
        <Descriptions title="报名限制条件" :list="limitList" :detail="detail" class="mb-24"></Descriptions>
        <a-descriptions :column="1">
          <a-descriptions-item label="仅以下志工可报名">
            <VolunteerList :info="detail" :show-label="false"></VolunteerList>
          </a-descriptions-item>
          <a-descriptions-item label="仅已选中的小组可参与">
            <LimitGroupList :info="detail" :type="1"></LimitGroupList>
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit" v-if="detail && detail.status < 3">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import {
  numberOptions,
  catOptions,
  volunStatusOptions,
  reportOptions,
  getActivityStatus,
} from "../../../common/hr/volunteer";
import { isEmpty, timestampConvertString } from "../../../common/js/tool";
import ActivityForm from "./components/ActivityForm.vue";
import Descriptions from "./components/Descriptions.vue";
import { getSystemRoleName } from "../../../permission/subsystem";
import VolunteerList from "./components/VolunteerList.vue";
import LimitGroupList from "./components/LimitGroupList.vue";

export default {
  name: "ActivityDetail",
  components: {
    ActivityForm,
    Descriptions,
    VolunteerList,
    LimitGroupList,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      limitList: [],
      columns: [
        {
          title: "核销人名称",
          dataIndex: "user_id",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <open-data type="userName" openid={text[0]} />;
            } else {
              return "-";
            }
          },
        },
        { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          customRender: (text) => {
            return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
          },
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
        showSizeChanger: true,
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-activity/${this.id}`,
          method: "GET",
          params: {
            expand: "activityType,userRole,joinGroup",
          },
        }).then((res) => {
          getActivityStatus(res);
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      let { is_limit, userRole } = this.detail;
      let basicList = [];
      let limitList = [];
      basicList.push(
        {
          key: "name",
          label: "活动名称",
        },
        {
          label: "活动时间",
          slot: (detail) => {
            let { activity_start_time, activity_end_time } = detail;
            return <span>{timestampConvertString(activity_start_time) + " ~ " + timestampConvertString(activity_end_time)}</span>;
          },
        },
        {
          key: "location",
          label: "活动地点",
        },
        {
          label: "可打卡时间",
          slot: (detail) => {
            let { start_time, end_time } = detail;
            return <span>{timestampConvertString(start_time) + " ~ " + timestampConvertString(end_time)}</span>;
          },
        },
        {
          label: "报名时间",
          slot: (detail) => {
            let { enroll_start, enroll_end } = detail;
            return <span>{timestampConvertString(enroll_start) + " ~ " + timestampConvertString(enroll_end)}</span>;
          },
        },
        {
          key: "is_limit",
          label: "名额限制",
          options: numberOptions,
          select: true,
        }
      );
      if (is_limit == 2) {
        basicList.push({
          key: "quota_num",
          label: "名额数量",
        });
      }
      basicList.push(
        {
          label: "活动类型",
          slot: (detail) => {
            return <span>{detail.activityType?.name}</span>;
          },
        },
        {
          key: "recruit_num",
          label: "招募总人数",
        },
        {
          key: "is_report",
          label: "需要报备",
          options: reportOptions,
          tag: true,
        },
        {
          key: "img",
          label: "活动封面",
          img: true,
        },
        {
          key: "info",
          label: "活动介绍",
          rich: true,
        }
      );
      limitList.push(
        {
          key: "confirm_people",
          label: "可确认志工报名名单的人",
          options: catOptions,
          tag: true,
        },
        {
          key: "volunteer_status",
          label: "可进行报名的志工状态",
          options: volunStatusOptions,
          tag: true,
        },
        {
          label: "可进行报名的年龄",
          slot: (detail) => {
            let { min_age, max_age } = detail;
            if (!isEmpty(min_age) && !isEmpty(max_age)) {
              return (
                <div>
                  <span>{min_age}岁</span>
                  <span> ~ </span>
                  <span>{max_age}岁</span>
                </div>
              );
            } else if (!isEmpty(min_age)) {
              return <span>最小{min_age}岁</span>;
            } else if (!isEmpty(max_age)) {
              return <span>最大{max_age}岁</span>;
            } else {
              return <span>-</span>;
            }
          },
        }
      );
      this.limitList = limitList;
      this.basicList = basicList;
      this.list = userRole;
    },
    handleEdit() {
      this.edit = true;
      this.initial = this.detail;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/volunteer-activity/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
